@use 'mixins';

.Footer {
    background: rgb(var(--lighterInvertedBackground));
    text-align: center;

    :global(.theme-br) & {
        background: rgb(var(--lightBackground));
    }

    a {
        @extend %link-medium;

        text-decoration: none;
        color: rgb(var(--invertedTextColor));

        :global(.theme-br) & {
            color: inherit;
        }

        &:hover {
            color: rgb(var(--invertedTextColor));
            text-decoration: underline;

            :global(.theme-br) & {
                color: inherit;
            }
        }
    }

    h3 {
        margin-top: var(--largePadding);
        margin-bottom: var(--standardPadding);
        white-space: nowrap;
    }

    .spacer {
        margin: var(--largePadding) 0;
    }

    @include mixins.responsive(m, above) {
        padding-top: var(--standardPadding);
        text-align: initial;
    }
}
