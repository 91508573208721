@use 'mixins';

.AddressStep {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    .address-form {
        display: grid;
        width: 100%;
        margin: var(--standardPadding) 0;
        padding: 0 var(--standardPadding);
        background-color: initial;
        grid-template-areas:
            'postcode postcode'
            'house-number addition';
        grid-gap: var(--smallPadding);

        .postcode {
            grid-area: postcode;
        }

        .house-number {
            margin-right: var(--smallPadding);
            grid-area: house-number;
        }

        .addition {
            margin-left: var(--smallPadding);
            grid-area: addition;
        }
    }

    @include mixins.responsive(m, below) {
        .address-form {
            .postcode [class^='InputElement'] {
                margin: initial;
            }
        }
    }

    @include mixins.responsive(l, above) {
        .address-form {
            width: 65%;
            grid-template-areas: 'postcode house-number addition';
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: var(--standardPadding);

            .postcode {
                margin-right: initial;
            }

            .house-number {
                margin-right: initial;
            }

            .addition {
                margin-left: initial;
            }
        }
    }
}
