@use 'mixins';

.OverviewContainer {
    .Overview {
        display: flex;
        flex-direction: column;
        background-color: rgb(var(--lightSectionBack));
        padding: var(--largePadding) var(--standardPadding);

        .title {
            @extend %link-medium;

            display: flex;
            flex-direction: row;
            padding: var(--standardPadding) 0;
            border-bottom: 1px solid rgb(var(--alternativeBackground));
        }

        tr {
            display: flex;
            flex-direction: row;
            padding: var(--standardPadding) 0;
            justify-content: space-between;
        }

        .totalPrice {
            @extend %link-medium;

            padding: var(--standardPadding) 0 0 0;
            border-top: 1px solid rgb(var(--alternativeBackground));
        }
    }

    .outro {
        @extend %caption-medium;

        padding: var(--standardPadding) 0;
        font-weight: normal;

        .checkboxField {
            input {
                margin-right: var(--smallPadding);
            }
        }

        .general {
            @extend %caption-medium;

            padding-bottom: var(--largePadding);
            font-weight: normal;
        }
    }

    @include mixins.responsive(m, below) {
        .outro .checkboxField label {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }
    }
}
