@use 'mixins';

.AddressInfo {
    display: block;
    position: relative;
    width: 100%;
    margin: var(--standardPadding);
    padding: var(--standardPadding);
    background-color: rgba(var(--successColor), 0.1);
    border-radius: var(--standardRadius);

    p {
        padding: 0 var(--standardPadding);

        &:first-of-type {
            margin-bottom: var(--standardPadding);
        }
    }

    &.error {
        background-color: rgba(var(--errorColor), 0.1);
    }

    @include mixins.responsive(l, above) {;
        width: 65%;
        margin: 0 auto var(--standardPadding) auto;
    }
}
